/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, {
	reactive,
	toRefs,
	// computed,
} from '@vue/composition-api'

Vue.use(VueCompositionApi)
import Store from '@/store'
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'

const orderHeaderState = reactive({
	ourCompanyOrder: '',
	customerIdOrder: '',
	customerTitleOrder: '',
	customerNicknameOrder: '',
	customerReferenceOrder: '',
	customerEmailOrder: '',
	customerOrgNumOrder: '',
	orderIssueDate: '',
	keyOrderHeaders: 1,
	customerAddressArray: [],
	isDatePickerDisabled: false,
})
const componentState = reactive({
	showSearchBox: '',
	showOrderHeaders: '',
})
const modelOrder = reactive({
	showExchangeFieldsOnForm: '',
	orderType: '',
	orderLanguage: '', // **
	orderExchangeUnit: '', // **
	orderExchangeRate: '', // **
	dispatchAddressId: '',
	invoiceAddressId: '',
})
const schemaOrder = reactive({
	groups: [
		{
			fields: [
				{
					type: 'select',
					label: 'Order Type',
					model: 'orderType',
					id: 'order-type',
					hint: 'Please select order type',
					values: ['Inland', 'Inside EU', 'Outside EU'],
					// values: function () {
					// 	return [
					// 		{ id: 'Inland', name: 'Inland' },
					// 		{ id: 'Inside EU', name: 'Inside EU' },
					// 		{ id: 'Outside EU', name: 'Outside EU' },
					// 	]
					// },
					default: 'Inland',
					featured: true,
					required: true,
					readonly: false,
					disabled: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							console.warn(
								'Validation error in Order type field! Errors:',
								errors,
							)
						} else {
							if (
								model.orderType === 'Inside EU' ||
								model.orderType === 'Outside EU'
							) {
								model.showExchangeFieldsOnForm = true
							} else {
								model.showExchangeFieldsOnForm = false
								model.orderLanguage = 'se'
								model.orderExchangeUnit = 'kr.'
								model.orderExchangeRate = 1.0
							}
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return model && model.showExchangeFieldsOnForm === true
					},
					type: 'select',
					label: 'Order Language',
					model: 'orderLanguage',
					hint: 'Please select order language',
					values: ['en', 'se'],
					featured: true,
					required: true,
					readonly: false,
					disabled: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							Store.dispatch('setDisableSaveOrderHeaders', true)
							console.warn(
								'Validation error in Order Language field! Errors:',
								errors,
							)
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return model && model.showExchangeFieldsOnForm === true
					},
					type: 'select',
					label: 'Order Exchange Unit',
					model: 'orderExchangeUnit',
					hint: 'Please select order exchange unit',
					values: ['kr.', '€', '$'],
					featured: true,
					required: true,
					readonly: false,
					disabled: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							Store.dispatch('setDisableSaveOrderHeaders', true)
							console.warn(
								'Validation error in Order Exchange Unit field! Errors:',
								errors,
							)
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return model && model.showExchangeFieldsOnForm === true
					},
					type: 'cleave',
					label: 'Order Exchange Rate',
					model: 'orderExchangeRate',
					placeholder: 'Please enter order exchange rate',
					featured: true,
					required: true,
					readonly: false,
					disabled: false,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 4,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							console.log('ERRor!!!')
							console.warn(
								'Validation error in Order Exchange Rate field! Errors:',
								errors,
							)
							// console.log('*******', Store.getters.getDisableSaveOrderHeaders)
						} else {
							console.log('NO ERROR!!!')
						}
					},
				},
				{
					type: 'select',
					label: 'Dispatch Address',
					model: 'dispatchAddressId',
					placeholder: 'Please select dispatch address',
					required: true,
					featured: true,
					readonly: false,
					disabled: false,
					values: [],
					// validator: ['required', 'notnull'],
				},
				{
					type: 'select',
					label: 'Invoice Address',
					model: 'invoiceAddressId',
					placeholder: 'Please select sale-invoice address',
					required: true,
					featured: true,
					readonly: false,
					disabled: false,
					values: [],
					// validator: ['required', 'notnull'],
				},
			],
		},
	],
})
const formOptionsOrder = reactive({
	validateAfterLoad: true,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'orderForm',
})

export default function storeOrderHeaders() {
	return {
		componentState,
		orderHeaderState,
		...toRefs(orderHeaderState),
		modelOrder,
		schemaOrder,
		formOptionsOrder,
		...toRefs(modelOrder),
	}
}
